export const URLS = {
  /*USER URL */
  GET_USER_DETAILS: "/users",
  ADD_USER_DETAILS: "/users",
  UPDATE_USER_DETAILS: "/user/:_id",
  DELETE_USER_DETAILS: "/user/:_id",
  GET_USER_DETAILS_BY_PROOF: "/user/proof/",
  USER_LOGIN: "/api/auth/login",
  USER_REGISTRATION: "/api/auth/register",
  GET_PRMOTIONS: "/api/promo-package",
  GET_EVENTS: "/web/events",
  GET_EVENT_VACANT: "/event/vacant",
  GET_USER_BOUGHT_PACKAGES:"/api/promo-package/user/bought?fetchAll=true",
  GET_USER_BOUGHT_VOUCHORS:"/api/promo-code/bought?fetchAll=true",
  GET_PROMOCODE: "/api/promo-code",
  GET_PROMOCODE_BY_CODE: "/promo-code-by-code/:code",
  GET_PACKAGES_DISCOUNT:"/reservations/check-package-discounts/:_id",
  GET_PACKAGES_BY_ID:"/api/promo-package/:_id",
  GET_ROOM_TYPES_ID:"/api/promo-package/periods/:_id",
  GET_EVENTS_BYUSER: "/api/event/user/:_id",

  /*ROLE URL */
  GET_ROLE_DETAILS: "/roles",
  ADD_ROLE_DETAILS: "/roles",
  UPDATE_ROLE_DETAILS: "/role/:_id",
  DELETE_ROLE_DETAILS: "/role/:_id",
  GET_CUSTOMER_DATA: "/api/customer/:_id",
  UPDATE_PASSWARD:"/api/customer/pass-update",
  SELECT_ROOM_AUTOMATICALLY: "/api/package/room-types/unblocked-room",
  GET_PAYMENT_LIST: "/api/reservations/get-payments",
  UPDATE_INCLUDED_TRANSPORTATIONS: "/api/reservations/edit-transportation/:_id",
  UPDATE_TRANSPORTATION: "/reservation/trans-edit/:_id",
  /*DEPARTMENT URL */
  GET_DEPARTMENT_DETAILS: "/departments",
  ADD_DEPARTMENT_DETAILS: "/departments",
  UPDATE_DEPARTMENT_DETAILS: "/department/:_id",
  DELETE_DEPARTMENT_DETAILS: "/department/:_id",

  /*BED URL */
  GET_BED_DETAILS: "/beds",
  ADD_BED_DETAILS: "/beds",
  UPDATE_BED_DETAILS: "/bed/:_id",
  DELETE_BED_DETAILS: "/bed/:_id",
  CHANGE_STATUS_BED_DETAILS:"/bed/:_id",
  DELETE_BED_TYPES_DETAILS: "/room-type/combination/:_id/:_comboId",

   /*AMENITY URL */
   GET_AMENITY_DETAILS: "/amenities",
   ADD_AMENITY_DETAILS: "/amenities",
   UPDATE_AMENITY_DETAILS: "/amenity/:_id",
   DELETE_AMENITY_DETAILS: "/amenity/:_id",
   UPLAOD_AMENITY_IMAGE:"/upload",

    /*BUILDINGS URL */
    GET_BUILDINGS_DETAILS: "/buildings",
    ADD_BUILDINGS_DETAILS: "/buildings",
    UPDATE_BUILDINGS_DETAILS: "/building/:_id",
    DELETE_BUILDINGS_DETAILS: "/building/:_id",

  CHANGE_STATUS_BED_DETAILS: "/bed/:_id",

    

//  GET_ROOMTYPE_DETAILS:"/room-types",
 

/**Filter Transaction */
GET_ONLINE_PAYMENT: "/online-payment",
GET_ONLINE_PAYMENT_WITH_ID:'online-payment/:_id ',

 /* ROOMTYPE URL */
 FILE_UPLOAD:"/upload",
 GET_ROOMTYPE_DETAILS:"/room-types",
 ADD_ROOMTYPE_DETAILS:"/room-types",
 UPDATE_ROOMTYPE_DETAILS:"/room-type/:_id",
 DELETE_ROOMTYPE_DETAILS:"/room-type/:_id",
 GET_ROOM_CODE:"/room-code-availability/:code",
 UPDATE_ROOM_TYPE_PRIORITY:"/room-types/priority",
 GET_ROOM_AVAILABLE_BEDCOMBO:"/tour-package/available-bedCombos",

 /*ROOMNUMBER URL */
 GET_ROOMNUMBER_DETAILS: "/rooms",
 ADD_ROOMNUMBER_DETAILS: "/rooms",
 UPDATE_ROOMNUMBER_DETAILS: "/room/:_id",
 DELETE_ROOMNUMBER_DETAILS: "/room/:_id",
 CHECK_ROOMNUMBER_UNIQUENESS:"/room-number-availability/:roomNumber",
 DUPLICATE_ROOM:"/rooms/duplicate",

  /*COMPANIES URL */
  GET_COMPANIES_DETAILS: "/companies",
  ADD_COMPANIES_DETAILS: "/companies",
  UPDATE_COMPANIES_DETAILS: "/company/:_id",
  DELETE_COMPANIES_DETAILS: "/company/:_id",
  GET_BOAT_QTY: "/vehicles/boat-qty",

   /*CATEGORY URL */
   GET_CATEGORY_DETAILS: "/categories",
   ADD_CATEGORY_DETAILS: "/categories",
   UPDATE_CATEGORY_DETAILS: "/category/:_id",
   DELETE_CATEGORY_DETAILS: "/category/:_id",

   /*TRIPCOST URL */
   GET_TRIPCOST_DETAILS: "/trip-costs",
   ADD_TRIPCOST_DETAILS: "/trip-costs",
   UPDATE_TRIPCOST_DETAILS: "/trip-cost/:_id",
   DELETE_TRIPCOST_DETAILS: "/trip-cost/:_id",
   GET_LOCATION: "/locations",

   /* ADDONS CATEGORY */
   GET_ADDON_CATEGORY_DETAILS: "/addon-categories",
   ADD_ADDON_CATEGORY_DETAILS: "/addon-categories",
   UPDATE_ADDON_CATEGORY_DETAILS: "/addon-category/:_id",
   DELETE_ADDON_CATEGORY_DETAILS: "/addon-category/:_id",
   GET_ADDON_CATEGORY_SUBCATEGORY_DETAILS:"/addon-category/:_id/addon-sub-categories",
   GET_ADDONS_BY_CATEGORIES: "/addons/by-categories",
   SEARCH_BY_ADDON_CATEGORY:"/addon/by-category/:category",
   UPDATE_ADDON_CATEGORY_PRIORITY_DETAILS:"/addon-categories/priority",

   /* ADDONS SUB CATEGORY */

   GET_ADDON_SUB_CATEGORY_DETAILS: "/addon-sub-categories",
   ADD_ADDON_SUB_CATEGORY_DETAILS: "/addon-sub-categories",
   UPDATE_ADDON_SUB_CATEGORY_DETAILS: "/addon-sub-category/:_id",
   DELETE_ADDON_SUB_CATEGORY_DETAILS: "/addon-sub-category/:_id",
   GET_ADDON_CATEGORY_SUBCATEGORY_DETAILS:"/addon-category/:_id/addon-sub-categories",


    /*VEHICLE URL */
    GET_VEHICLE_DETAILS: "/vehicles",
    ADD_VEHICLE_DETAILS: "/vehicles",
    UPDATE_VEHICLE_DETAILS: "/vehicle/:_id",
    DELETE_VEHICLE_DETAILS: "/vehicle/:_id",

     /*DIVINGCOURSE URL */
     GET_DIVINGCOURSE_DETAILS: "/diving-courses",
     ADD_DIVINGCOURSE_DETAILS: "/diving-courses",
     UPDATE_DIVINGCOURSE_DETAILS: "diving-course/:_id",
     DELETE_DIVINGCOURSE_DETAILS: "diving-course/:_id",
     DIVING_STAFF:'/users/diving',


     /*DIVINGDEPARTMENTSETTING URL */
     GET_DIVINGDEPARTMENT_SETTING_DETAILS: "/diving-departments",
     ADD_DIVINGDEPARTMENT_SETTING_DETAILS: "/diving-departments",
     UPDATE_DIVINGDEPARTMENT_SETTING_DETAILS: "diving-department/:_id",
     DELETE_DIVINGDEPARTMENT_SETTING_DETAILS: "diving-department/:_id",
     GET_STAFF_DETAILS:"/users/diving-notassigned",


     
  /*LOCATIONS URL */
   GET_LOCATIONS_DETAILS: "/locations",
   ADD_LOCATIONS_DETAILS: "/locations",
   UPDATE_LOCATIONS_DETAILS: "/location/:_id",
   DELETE_LOCATIONS_DETAILS: "/location/:_id",

   /*Meals URL*/
   GET_MEALS_DETAILS: "./meals",
   ADD_MEALS_DETAILS: "/meals",
   UPDATE_MEALS_DETAILS: "/meal/:_id",
   DELETE_MEALS_DETAILS: "/meal/:_id",
   GET_RESTAURANTS_DETAILS: "/restaurants",

     /*ADDON URL */
     GET_ADDON_DETAILS: "/addons",
     ADD_ADDON_DETAILS: "/addons",
     UPDATE_ADDON_DETAILS: "/addon/:_id",
     DELETE_ADDON_DETAILS: "/addon/:_id",

  /* FACILITIES URL*/
  GET_FACILITY_DETAILS: "/facilities",
  ADD_FACILITY_DETAILS: "/facilities",
  UPDATE_FACILITY_DETAILS: "/facility/:_id",
  DELETE_FACILITY_DETAILS: "/facility/:_id",

   /*CATEGORY URL */
   GET_RESTURANTS_DETAILS: "/restaurants",
   ADD_RESTURANTS_DETAILS: "/restaurants",
   UPDATE_RESTURANTS_DETAILS: "/restaurant/:_id",
   DELETE_RESTURANTS_DETAILS: "/restaurant/:_id",

    /* SURCHARGES URL */
    GET_SURCHARGES_DETAILS: "/sur-charges",
    ADD_SURCHARGES_DETAILS: "/sur-charges",
    UPDATE_SURCHARGES_DETAILS: "/sur-charge/:_id",
    DELETE_SURCHARGES_DETAILS: "/sur-charge/:_id",
   /*TAXES URL*/
   GET_TAXES_DETAILS: "/taxes",
   ADD_TAXES_DETAILS: "/taxes",
   UPDATE_TAXES_DETAILS: "/tax/:_id",
   DELETE_TAXES_DETAILS: "/tax/:_id",

    /*TRAVELAGENT URL */
    GET_TRAVELAGENT_DETAILS: "/agent-categories",
    ADD_TRAVELAGENT_DETAILS: "/agent-categories",
    UPDATE_TRAVELAGENT_DETAILS: "/agent-category/:_id",
    DELETE_TRAVELAGENT_DETAILS: "/agent-category/:_id",

     /*Package URL*/
   GET_PACKAGE_DETAILS: "/package",
   ADD_PACKAGE_DETAILS: "/package",
   UPDATE_PACKAGE_DETAILS: "/package/:_id",
   DELETE_PACKAGE_DETAILS: "/package/:_id",
   ADD_NEW_PACKAGE_GROUP: "/agent-groups",
   ADD_ACTIVE_TRAVELAGENT: "/travelagent?agentApproved=true",
   GET_ACTIVE_CUSTOMER: "/customers",
   GET_PACKAGE_OPTION: "/api/package/option/:packageId",

   GET_CURRENCY_DETAILS: "",
   ADD_CURRENCY_DETAILS: "",
   UPDATE_CURRENCY_DETAILS: "",
   DELETE_CURRENCY_DETAILS: "",
   CHANGE_STATUS_CURRENCY_DETAILS:"",
   GET_ALL_PACKAGE_LIST: "/api/package/all-available",
  ROOMS_AVAILABILITY: "/rooms-availability",

       /* ADDITIONAL CHARGES URL */
       GET_ADDITIONAL_CHARGES_DETAILS: "/additional-charges",
       ADD_ADDITIONAL_CHARGES_DETAILS: "/additional-charges",
       UPDATE_ADDITIONAL_CHARGES_DETAILS: "/additional-charge/:_id",
       DELETE_ADDITIONAL_CHARGES_DETAILS: "/additional-charge/:_id",

   GET_PACKAGE_DETAILS: "/tour-packages",
   ADD_PACKAGE_DETAILS: "/tour-packages",
   UPDATE_PACKAGE_DETAILS: "/tour-package/:_id",
   DELETE_PACKAGE_DETAILS: "/tour-package/:_id",
   ADD_PACKAGE_BOOKPERIOD_DETAILS: "/tour-package/:_id/periods",
   DUPLICATE_PACKAGE_DETAILS: "/tour-package/duplicate",
   

   GET_CURRENCY_DETAILS: "/currencies",
   ADD_CURRENCY_DETAILS: "/currencies",
   UPDATE_CURRENCY_DETAILS: "/currency/:_id",
   DELETE_CURRENCY_DETAILS: "/currency/:_id",

   GET_BOOKING_ENQUIRY_DETAILS: "/booking-enquiries",
   ADD_BOOKING_ENQUIRY_DETAILS: "/booking-enquiries",
   UPDATE_BOOKING_ENQUIRY_DETAILS: "/booking-enquiry/:_id",
   DELETE_BOOKING_ENQUIRY_DETAILS: "/booking-enquiry/:_id",

   GET_BOOKING_SETTING_DETAILS: "/lead-sources",
   ADD_BOOKING_SETTING_DETAILS: "/lead-sources",
   UPDATE_BOOKING_SETTING_DETAILS: "/lead-source/:_id",
   DELETE_BOOKING_SETTING_DETAILS: "/lead-source/:_id",
   GET_BOOKING_DATA:"/api/reservations/",
   GET_BOOKING_SUMMARY: "/reservation/:_id/summary",
   BLOCK_ROOM_TEMP: "/tour-package/temp-room-block/:_id",
   GET_INCLUDED_ADDONS: "/tour-package/locations",
   ADD_OFFLINE_PAYMENT: "/reservation/:_id/offline-payment",
   UPDATE_RESERVATIONS: "/reservation/:_id",
   USER_UPDATE_RESERVATIONS: "/user-reservation/:_id",
   GET_BOOKING_JSON: "/api/reservations/booking-json/:_id",
   GET_EXCLUDED_BOAT: "/trip-costs/excludedboat",
   ADD_TRANSPORTATION_NOTES: "/reservation/:_id/transportation/:transId",
   GET_TRANS_REPORT: "/report/transportation/:date",
   EDIT_BOOKING_JSON:"/api/pre-reservation/edit-bookingJson/:_id",
   GET_PREBOOKING_JSON:"/api/pre-reservation/get-bookingJson/:_id",
   EDIT_RES_BOOKING_JSON: "api/reservations/edit-bookingJson/:_id",
   ROOM_TIMER:"/room-timer",
   UN_BLOCK_ROOM_TEMP: "/tour-package/temp-room-block/:_id",
   EXTEND_ROOM_TIME:"/tour-package/temp-room-block",
  //  Tour packages
  GET_TOUR_PACKAGES: "/api/package",
  GET_PACKAGE_BY_ID: "/tour-package/:_id",
  GET_TOUR_PACKAGE_DETAILS: "/tour-packages",
  GET_ROOM_TYPES: "/tour-package/room-types",

  // Influencer Package
  GET_INFLUENCER_PACKAGE: '/api/influencer-package/:_id/:agentId',

  // SURCHARGE CALENDER
  GET_SURCHARGE_CALENDER:"/sur-charges-calender",
  ADD_SURCHARGE_CALENDER:"/sur-charges-calender",

    //PROMOCODE URL
   GET_PROMOCODE_DETAILS: "/promo-codes",
   ADD_PROMOCODE_DETAILS: "/promo-codes",
   UPDATE_PROMOCODE_DETAILS: "/promo-code/:_id",
   DELETE_PROMOCODE_DETAILS: "/promo-code/:_id",
   GET_PROMOLIST:"/api/promo-code/bought",


  GET_TYPEOFPAX_SETTING_DETAILS: "/pax-settings",
  ADD_TYPEOFPAX_SETTING_DETAILS: "/pax-settings",
  UPDATE_TYPEOFPAX_SETTING_DETAILS:"/pax-setting/:_id",

  GET_ROOM_TYPES: "/tour-package/room-types",
  GET_ROOM_NUMBER: "/tour-package/room-by-room-type",
  GET_ADDITIONAL_PACKAGES: "tour-package/room-type-additional-packages",
  ADD_RESERVATIONS: "/api/pre-reservation",
  EDIT_RESERVATIONS: "/api/reservations",
  GET_BOOKING_DETAILS: "/tour-package/booking-details",
  GET_ADDONS_CATEGORY: "/tour-package/addons/by-categories",
  GET_LOCATION_START_POINTS: "/trip-cost/location/start-points",
  GET_LOCATION_END_POINTS: "/trip-cost/location/:_id/end-points/:type",

  //PERMISSIONS
  GET_PERMISSION_DETAILS: '/permission-settings',
  GET_APP_MODULES_DETAILS: '/app-modules',
  ADD_PERMISSION_DETAILS: '/permission-settings',

  GET_TRAVELAGENT_REGISTRATION_DETAILS:"/travelagent",
  ADD_TRAVELAGENT_REGISTRATION_DETAILS:"/travelagent/add",
  UPDATE_TRAVELAGENT_REGISTRATION_DETAILS:"",
  DELETE_TRAVELAGENT_REGISTRATION_DETAILS:"",
  UPDATE_TRAVELAGENT_REGISTRATION_STATUS:"/travelagent/:_id",
  GET_TRAVELAGENT_REGISTRATION_STATUS_OPTIONS:"/status",

  //RESERVATION 

  GET_RESERVATION_INFO:"/reservation/:_id",
  GET_PRE_RESERVATION_INFO: "api/pre-reservation/:_id",
  UPDATE_GEUST_RESERVATION_INFO:"/reservation-room/:_id",
  SET_RESERVATION_PAYMENT_STATUS:"/reservation/:_id/payment/:paymentid",

  //AGENT PROFILE
  GET_AGENT_PROFILE_DETAILS: "/travelagent-info/:_id",
  ADD_AGENT_PROFILE_DETAILS: "/travelagent-info/:_id",
  UPDATE_AGENT_PROFILE_DETAILS: "/travelagent-info/:_id",
  AGENT_FILE_UPLOAD:"/upload",
  GET_AGENT_DETAILS: "/travelagent/:_id",
  GET_ROOMS_BY_ROOMTYPES: "/rooms-by-room-type",


  //Dashboard API
  GET_PAYMENT_REMINDER: "/dash/payment-remainder/:date",
  GET_EXPIRED_INVOICE: "/dash/payment-remainder/:date?type=expired",
  GET_DASBOARD_GRAPH:"/dash/graph/",
  GET_DASHBOARD_PAYTORESORT:"/dash/paytoresort/:date",
  GET_DASHBOARD_TODAYPICKUP:"/dash/todayPickUp/:date",
  GET_DASHBOARD_CARDCOUNT:"/dash/cards-count/:date",
  GET_DASHBOARD_CURRENTLIST:"/dash/currentList/:date",
  GET_DASHBOARD_UNCONFIRMED_PAY:"/dash/unconfirmed-payments",
  GET_DASHBOARD_CARDS_INFO: "/dash/cards-count/info/:date",
  GET_DASHBOARD_MONTHLY_REPORT : "/dash/monthly-report",
  GET_DASHBOARD_MONTHLY_SALES_REPORT: "/dash/monthly-report/sale",
  
  
  // agent Files
GET_AGENT_FILES: '/agent-email-file',
UPLOAD_AGENT_FILES:'/agent-email-file',
DELETE_AGENT_FILES: '/agent-email-file/:_id',
UPDATE_AGENT_FILES: '/agent-email-file/:_id',

  //Leave API
  GET_LEAVE_LIST:"/users/leave",
  UPDATE_LEAVE_LIST:"/user/leave/:_id",

  //Online status
  GET_PAYMENT_STATUS: "/api/eghl/online/:_id",
  CHANGE_ROOM: "/rooms-change/:resId",
  BLOCK_ROOM:"/rooms-block/:_id",
  UPDATE_NOTES: "/api/reservations/edit-res-info/:_id",
  GET_WALLET: "/api/wallet",
  PAY_PROMO_PACKAGE_FROM_WALLET:"/api/wallet/promo-package",
  PAY_EVENT_FROM_WALLET: "/api/wallet/event",
  BLOCK_PRMO_CODE: "/api/promo-code/block",
  BOOK_PRMO_CODE: "/api/promo-code/buy",
  WALLET_PRMOCODE: "/api/wallet/promo-code",
  //Wallet Information
  GET_WALLET_INFORMATION: "/api/wallet",
  GET_WALLET_PAYOUT: "api/wallet/payout",
  CANCEL_BOOKING:"/api/reservations/cancel/:_id",
  DELETE_TRANS:"/api/reservations/edit-exisiting-transportation/:_id/:tansId",
  ADD_NOTES:"/api/reservations/edit-exisiting-transportation/:_id/:transId",
  GET_SOCIAL_SHARE_DETAILS: "/social-share",
  GET_SHAREMODAL_DETAILS: "/api/rewards/settings",
  GET_BOOKING_RATES: "/api/package/period/:_id",
  GET_EXCLUDE_CHARGE: "/api/additional-charge",
  GET_COMPLETED_BOOKINGS: "/api//reservations/completed-booking/checkedIn",
  // Referral Clicks
  POST_REFERRAL_CLICKS: "/api/pre-reservation/referal-clicks",

  //surcharge
  GET_SURCHARGES_DETAILS: 'api/surcharge',
  GET_SURCHARGES_CALENDER_DETAILS: 'api/surcharge/calender',
  //ewallet 
  GET_EWALLET_SETTING_DETAILS: '/api/wallet-setting',

  //feedback
  GET_FEEDBACK_OEUSTION: '/feedback-questions',
  UPDATE_FEEDBACK_CUSTOMER: '/api/feedback',
  GET_CUSTOMER_FEEDBACK: '/api/feedback',

  //charted resort
  OFFLINE_PAYMENT: '/custom-invoice/payment-offline/:_id',
  CUSTOM_INVOICE_ID: '/custom-invoice/:_id',

 //agent info
 AGENT_INFO: 'api/agent-info',

 // Package Enquiry
 CREATE_PACKAGE_ENQUIRY: '/api/package-enquiry'
}