export const generateAppConfig = () => {
  let exportResult = {
    appRepoLocation: "http://localhost:3000",
    backendUrl: "http://localhost:3100",
    appBackendUrl: "http://localhost:4040",
    jsReportUrl: "http://localhost:5488",
  };
  if (process.env.REACT_APP_PRODUCTION_MODE === "TEST") {
    exportResult.appRepoLocation = "https://app-test.summerbayresort.com.my";
    exportResult.backendUrl = "https://api-test.summerbayresort.com.my";
    exportResult.appBackendUrl = "https://api-app-test.summerbayresort.com.my";
    exportResult.jsReportUrl = "https://report-test.summerbayresort.com.my";
  }
  if (process.env.REACT_APP_PRODUCTION_MODE === "STAGE") {
    exportResult.appRepoLocation = "https://app.travoxis.com";
    exportResult.backendUrl = "https://admin-api.travoxis.com";
    exportResult.appBackendUrl = "https://app-api.travoxis.com";
    exportResult.jsReportUrl = "https://report-test.summerbayresort.com.my";
  }
  if (process.env.REACT_APP_PRODUCTION_MODE === "PROD") {
    exportResult.appRepoLocation = "https://app.summerbayresort.com.my";
    exportResult.backendUrl = "https://api.summerbayresort.com.my";
    exportResult.appBackendUrl = "https://api.app.summerbayresort.com.my";
    exportResult.jsReportUrl = "https://report.summerbayresort.com.my";
  }
  return exportResult;
};
